import { data } from "../../data/targeting_browse";
import jwt_decode from "jwt-decode";
import Axios from "axios";

const state = {
  adSetWarning: {
    show: false,
    message: "",
  },
  FB: null,
  scope: null,
  user_jwt: null,
  showFacebookLoginDialog: false,
  fbStatus: null,
  user: {
    email: null,
    plan: null,
    stripe_customer_id: null,
    trial_start_time: null,
    trial_end_time: null,
    access_token: null,
    ad_created: null,
  },
  user_profile_picture: null,
  user_full_name: null,
  user_id: null,
  current_ad_preview_mode: null,
  available_ctas: {
    reach: [
      { key: "LEARN_MORE", label: "LEARN MORE" },
      { key: "APPLY_NOW", label: "APPLY NOW" },
      { key: "BOOK_TRAVEL", label: "BOOK NOW" },
      // {key: 'CALL', label: 'CALL NOW'},
      { key: "CONTACT_US", label: "CONTACT US" },
      { key: "DOWNLOAD", label: "DOWNLOAD" },
      // {key: 'GET_DIRECTIONS', label: 'GET DIRECTIONS'},
      { key: "GET_QUOTE", label: "GET QUOTE" },
      { key: "GET_SHOWTIMES", label: "GET SHOWTIMES" },
      { key: "LISTEN_NOW", label: "LISTEN NOW" },
      { key: "REQUEST_TIME", label: "REQUEST TIME" },
      // {key: 'SAVE', label: 'SAVE'},
      { key: "ORDER_NOW", label: "SEE MENU" },
      // {key: 'MESSAGE_PAGE', label: 'SEND MESSAGE'},
      // {key: 'WHATSAPP_MESSAGE', label: 'SEND WHATSAPP MESSAGE'},
      { key: "SHOP_NOW", label: "SHOP NOW" },
      { key: "SIGN_UP", label: "SIGN UP" },
      { key: "SUBSCRIBE", label: "SUBSCRIBE" },
      { key: "WATCH_MORE", label: "WATCH MORE" },
    ],
    traffic: [
      { key: "LEARN_MORE", label: "LEARN MORE" },
      { key: "APPLY_NOW", label: "APPLY NOW" },
      { key: "BOOK_TRAVEL", label: "BOOK NOW" },
      // {key: 'CALL', label: 'CALL NOW'},
      { key: "CONTACT_US", label: "CONTACT US" },
      { key: "DOWNLOAD", label: "DOWNLOAD" },
      { key: "GET_OFFER", label: "GET OFFER" },
      { key: "GET_QUOTE", label: "GET QUOTE" },
      { key: "GET_SHOWTIMES", label: "GET SHOWTIMES" },
      { key: "LISTEN_NOW", label: "LISTEN NOW" },
      { key: "REQUEST_TIME", label: "REQUEST TIME" },
      { key: "ORDER_NOW", label: "SEE MENU" },
      { key: "SHOP_NOW", label: "SHOP NOW" },
      { key: "SIGN_UP", label: "SIGN UP" },
      { key: "SUBSCRIBE", label: "SUBSCRIBE" },
      { key: "WATCH_MORE", label: "WATCH MORE" },
    ],
    engagement: [
      { key: "LEARN_MORE", label: "LEARN MORE" },
      { key: "GET_QUOTE", label: "GET QUOTE" },
      { key: "MESSAGE_PAGE", label: "SEND MESSAGE" },
      // {key: 'WHATSAPP_MESSAGE', label: 'SEND WHATSAPP MESSAGE'},
      { key: "SHOP_NOW", label: "SHOP NOW" },
    ],
    "lead generation": [
      { key: "LEARN_MORE", label: "LEARN MORE" },
      { key: "APPLY_NOW", label: "APPLY NOW" },
      { key: "BOOK_TRAVEL", label: "BOOK NOW" },
      { key: "DOWNLOAD", label: "DOWNLOAD" },
      { key: "GET_OFFER", label: "GET OFFER" },
      { key: "GET_QUOTE", label: "GET QUOTE" },
      { key: "SIGN_UP", label: "SIGN UP" },
      { key: "SUBSCRIBE", label: "SUBSCRIBE" },
    ],
  },
};

const mutations = {
  SET_SCOPE(state, scope) {
    state.scope = scope;
  },
  SET_FB(state, FB) {
    state.FB = FB;
  },
  SET_FB_LOGIN_VISIBILITY(state, boolean) {
    state.showFacebookLoginDialog = boolean;
  },
  SET_FB_STATUS(state, model) {
    state.fbStatus = model;
  },
  SET_ACCESS_TOKEN(state, token) {
    state.user.access_token = token;
  },
  LOGGED_IN(state, FB) {
    if (FB) {
      state.FB = FB;
      state.user.access_token = FB.getAccessToken();
    }
  },
  UPDATE_USER_JWT(state, jwt) {
    state.user_jwt = jwt;
    let decoded_jwt = jwt_decode(jwt);
    state.user.email = decoded_jwt.email;
    state.user.plan = decoded_jwt.plan;
    state.user.trial_start_time = decoded_jwt.trial_start_time;
    state.user.trial_end_time = decoded_jwt.trial_end_time;
    state.user.ad_created = decoded_jwt.ad_created;
  },
  UPDATE_FB_USER(state, data) {
    state.user_profile_picture = data.picture.data.url;
    state.user_full_name = data.name;
    state.user_id = data.id;
  },
  DISCONNECT_FB_USER(state) {
    state.user_profile_picture = null;
    state.user_full_name = null;
    state.user_id = null;
  },
  SWITCH_AD_PREVIEW_MODE(state, mode) {
    state.current_ad_preview_mode = mode;
  },
};

const actions = {
  connectToFacebook: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      const fb_access_token = state.FB.getAccessToken();
      commit("SET_ACCESS_TOKEN", fb_access_token);
      commit("SET_FB_LOGIN_VISIBILITY", false);
      state.FB.api("/me?fields=picture,name,id", "get", (response) => {
        commit("UPDATE_FB_USER", response);
        Axios.post("/v1/facebook-connections", { fb_access_token })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },
  disconnectFromFacebook: async ({ commit }) => {
    commit("SET_ACCESS_TOKEN", null);
    commit("DISCONNECT_FB_USER");
    return;
  },
  fetchFbUser: ({ commit, state, getters }) => {
    return new Promise((resolve, reject) => {
      state.FB.api(
        "/me?fields=picture,name,id",
        "get",
        getters.apiTokenParam,
        (response) => {
          if (response.error) {
            commit("SET_ACCESS_TOKEN", null);
            reject(response.error);
          } else {
            commit("UPDATE_FB_USER", response);
            resolve();
          }
        }
      );
    });
  },
  UPDATE_USER_JWT: ({ commit }, jwt) => {
    commit("UPDATE_USER_JWT", jwt);
  },
  registerLogin: ({ commit }, FB) => {
    commit("LOGGED_IN", FB);
  },

  getAdPreview: ({ commit, getters }, adId) => {
    return new Promise((resolve) => {
      state.FB.api(
        `/${adId}/previews?previews`,
        {
          ad_format: "MOBILE_FEED_STANDARD",
          ...getters.apiTokenParam,
        },
        (response) => {
          commit("UPDATE_AD_PREVIEW_HTML", response.data[0].body);
          commit("UPDATE_PREVIEW_STATUS", false);
          resolve(response);
        }
      );
    });
  },

  generateAdPreview: (
    { commit, state, getters, rootState, rootGetters },
    adFormat
  ) => {
    // state.adCreative.object_story_spec.page_id = state.fbPage.id
    // state.adCreative.isLoading = true

    if (adFormat == null) {
      adFormat = "MOBILE_FEED_STANDARD";
    }
    commit("UPDATE_PREVIEW_STATUS", true);
    if (typeof rootState.ad.adAccount == "undefined") {
      commit("UPDATE_PREVIEW_STATUS", false);
      return false;
    }

    commit("SWITCH_AD_PREVIEW_MODE", adFormat);

    state.FB.api(
      `/${rootState.ad.adAccount.id}/generatepreviews`,
      {
        ...getters.apiTokenParam,
        ad_format: adFormat,
        height: 800,
        creative: {
          object_story_spec: rootGetters.objectStorySpec,
          instagram_actor_id: rootGetters.selectedInstagramPage
            ? rootGetters.selectedInstagramPage.id
            : null,
        },
      },
      (response) => {
        if (response.error) {
          return;
        }
        commit(
          "UPDATE_AD_PREVIEW_HTML",
          response.data ? response.data[0].body : ""
        );
        commit("UPDATE_PREVIEW_STATUS", false);
      }
    );
  },

  getTargetingBrowseData: ({ commit }) => {
    // console.log(data)

    // todo: instead of use imported 'data' 'targeting_browse.json', call targetingbrowse api to retrieve live data
    commit("UPDATE_TARGETING_BROWSE_DATA", data);

    // state.FB.api(`/${rootGetters.adAccount.id}/targetingbrowse`, {
    // }, response => {
    //   commit('UPDATE_TARGETING_BROWSE_DATA', response.data)
    // })
  },

  remoteCreateAdSet: ({ state, getters, rootGetters, dispatch, commit }) => {
    let detailed_targeting_data = {};

    rootGetters.detailedTargetingItems.forEach((targetingItem) => {
      if (
        !Object.prototype.hasOwnProperty.call(
          detailed_targeting_data,
          targetingItem.type
        )
      ) {
        detailed_targeting_data[targetingItem.type] = [];
      }

      // detailed_targeting_data[targetingItem.type].push({
      //   id: targetingItem.id,
      //   name: targetingItem.name
      // })

      detailed_targeting_data[targetingItem.type].push(targetingItem.id);
    });

    // let interests = rootGetters.detailedTargetingItems.map(item => {
    //   return {
    //     id: targetingItem.id,
    //     name: rootGetters.targetingBrowseData[item.id].name
    //   }
    // })

    let geo_location_cities = rootGetters.adSet.geo_locations
      .filter((item) => {
        let geoLocation = JSON.parse(item);
        return geoLocation.type == "city";
      })
      .map((item) => {
        let geoLocation = JSON.parse(item);
        const specialAdsRadius =
          rootGetters.adSet.specialAdCategory !== "NONE"
            ? { radius: 15, distance_unit: "mile" }
            : {};
        return { key: geoLocation.key, ...specialAdsRadius };
      });

    let geo_location_countries = rootGetters.adSet.geo_locations
      .filter((item) => {
        let geoLocation = JSON.parse(item);
        return geoLocation.type == "country";
      })
      .map((item) => {
        let geoLocation = JSON.parse(item);
        return geoLocation.key;
      });

    let geo_location_regions = rootGetters.adSet.geo_locations
      .filter((item) => {
        let geoLocation = JSON.parse(item);
        return geoLocation.type == "region";
      })
      .map((item) => {
        let geoLocation = JSON.parse(item);
        return { key: geoLocation.key };
      });

    let zips = rootGetters.adSet.geo_locations
      .filter((item) => {
        let geoLocation = JSON.parse(item);
        return geoLocation.type == "zip";
      })
      .map((item) => {
        let geoLocation = JSON.parse(item);
        return {
          key: geoLocation.key,
        };
      });

    // let countries = [];
    // console.log(this.geo_location_countries)
    // if (this.geo_location_countries) {
    //   countries = this.geo_location_countries
    // }

    if (
      geo_location_countries.length == 0 &&
      geo_location_regions.length == 0 &&
      geo_location_cities.length == 0 &&
      zips.length == 0
    ) {
      geo_location_countries.push("US");
    }

    const leadGenParams = {};
    if (rootGetters.isLeadGen) {
      leadGenParams.optimization_goal = "LEAD_GENERATION";
      leadGenParams.promoted_object = { page_id: rootGetters.fbPage.id };
    }

    state.FB.api(
      `/${rootGetters.adAccount.id}/adsets`,
      "POST",
      {
        name: rootGetters.adName,
        daily_budget: rootGetters.adSet.dailyAdBudget * 100,
        start_time: Math.floor(
          rootGetters.adSet.startDateTime.getTime() / 1000
        ),
        end_time: rootGetters.adSet.campaignKeepRunning
          ? null
          : Math.floor(rootGetters.adSet.endDateTime.getTime() / 1000),
        campaign_id: rootGetters.adCampaign.id,
        billing_event: "IMPRESSIONS",
        bid_strategy: "LOWEST_COST_WITHOUT_CAP",
        // optimization_goal: "POST_ENGAGEMENT",
        targeting: {
          age_min: rootGetters.adSet.age_min,
          age_max: rootGetters.adSet.age_max,
          // behaviors: [],
          genders: [0, 1],
          facebook_positions: ["feed"],
          geo_locations: {
            countries: geo_location_countries,
            // countries: ["US"],
            regions: geo_location_regions,
            cities: geo_location_cities,
            zips: zips,
            location_types: ["recent", "home"],
          },
          flexible_spec: [
            {
              ...detailed_targeting_data,
            },
          ],
          publisher_platforms: rootGetters.adPlacements,
          device_platforms: rootGetters.devicePlatforms,
        },
        ...leadGenParams,

        // targeting: "{\"age_min\":20,\"age_max\":24,\"behaviors\":[{\"id\":6002714895372,\"name\":\"All travelers\"}],\"genders\":[1],\"geo_locations\":{\"countries\":[\"US\"],\"regions\":[{\"key\":\"4081\"}],\"cities\":[{\"key\":\"777934\",\"radius\":10,\"distance_unit\":\"mile\"}]},\"interests\":[{\"id\":\"6003032339492\",\"name\":\"Ariana Grande\"}],\"life_events\":[{\"id\":6002714398172,\"name\":\"Newlywed (1 year)\"}],\"facebook_positions\":[\"feed\"],\"publisher_platforms\":[\"facebook\",\"audience_network\"]}",
        // status: "PAUSED"
        status: "ACTIVE",
        ...getters.apiTokenParam,
      },
      (response) => {
        dispatch("LOAD_AD_SET", response, { root: true });

        if (response.error) {
          state.adSetWarning.show = true;
          state.adSetWarning.message = response.error.error_user_msg;
          state.temp = response;
          commit(
            "ui/SET_NEW_NOTIFICATION",
            {
              title: response.error.error_user_msg,
              type: "warning",
            },
            { root: true }
          );
        } else {
          state.adSetWarning.show = false;
          if ("targeting_spec" in response) {
            dispatch("remoteGetDeliveryEstimate", response);
          } else {
            dispatch("remoteGetDeliveryEstimate", {});
          }
        }
      }
    );

    state.FB.api(`/${rootGetters.adAccount.id}/agencies`, "POST", {
      permitted_tasks: "['MANAGE', 'ADVERTISE', 'ANALYZE', 'DRAFT']",
      business: 525570974292133,
      ...getters.apiTokenParam,
    });
  },

  remoteUpdateAdSet({ state, getters, rootGetters, dispatch, commit }) {
    return new Promise((resolve, reject) => {
      let detailed_targeting_data = {};

      rootGetters.detailedTargetingItems.forEach((targetingItem) => {
        if (
          !Object.prototype.hasOwnProperty.call(
            detailed_targeting_data,
            targetingItem.type
          )
        ) {
          detailed_targeting_data[targetingItem.type] = [];
        }

        // detailed_targeting_data[targetingItem.type].push({
        //   id: targetingItem.id,
        //   name: targetingItem.name
        // })
        detailed_targeting_data[targetingItem.type].push(targetingItem.id);
      });

      let geo_location_cities = rootGetters.adSet.geo_locations
        .filter((item) => {
          let geoLocation = JSON.parse(item);
          return geoLocation.type == "city";
        })
        .map((item) => {
          let geoLocation = JSON.parse(item);
          const specialAdsRadius =
            rootGetters.adSet.specialAdCategory !== "NONE"
              ? { radius: 15, distance_unit: "mile" }
              : {};
          return { key: geoLocation.key, ...specialAdsRadius };
        });

      let geo_location_countries = rootGetters.adSet.geo_locations
        .filter((item) => {
          let geoLocation = JSON.parse(item);
          return geoLocation.type == "country";
        })
        .map((item) => {
          let geoLocation = JSON.parse(item);
          return geoLocation.key;
        });

      let geo_location_regions = rootGetters.adSet.geo_locations
        .filter((item) => {
          let geoLocation = JSON.parse(item);
          return geoLocation.type == "region";
        })
        .map((item) => {
          let geoLocation = JSON.parse(item);
          return { key: geoLocation.key };
        });

      let zips = rootGetters.adSet.geo_locations
        .filter((item) => {
          let geoLocation = JSON.parse(item);
          return geoLocation.type == "zip";
        })
        .map((item) => {
          let geoLocation = JSON.parse(item);
          return {
            key: geoLocation.key,
          };
        });

      // let countries = [];
      // console.log(this.geo_location_countries)
      // if (this.geo_location_countries) {
      //   countries = this.geo_location_countries
      // }

      // if (geo_location_countries.length == 0 && geo_location_regions.length == 0 && geo_location_cities.length == 0) {
      //   geo_location_countries.push("US")
      // }

      let targeting = {
        age_min: rootGetters.adSet.age_min,
        age_max: rootGetters.adSet.age_max,
        // behaviors: [],
        genders: rootGetters.adSet.genders,
        facebook_positions: ["feed"],
        geo_locations: {
          countries: geo_location_countries,
          // countries: ["US"],
          regions: geo_location_regions,
          cities: geo_location_cities,
          zips: zips,
          location_types: ["recent", "home"],
        },
        // interests: [{ id: "6003032339492", name: "Ariana Grande"}],
        // interests: interests,
        flexible_spec: [
          {
            ...detailed_targeting_data,
          },
        ],
        publisher_platforms: rootGetters.adPlacements,
        device_platforms: rootGetters.devicePlatforms,
      };

      if (rootGetters.ad_mode == "edit") {
        dispatch("remoteGetDeliveryEstimate", {
          targeting_spec: targeting,
        });
      } else {
        dispatch("remoteGetDeliveryEstimate", {});
      }

      state.FB.api(
        `/${rootGetters.adSet.id}?fields=name,targeting`,
        "POST",
        {
          // daily_budget: rootGetters.adSet.dailyAdBudget * 100,
          // start_time: Math.floor(this.ad.startDateTime.getTime() / 1000),
          // end_time: this.ad.campaignKeepRunning ? null : Math.floor(this.ad.endDateTime.getTime() / 1000),
          start_time: Math.floor(
            rootGetters.adSet.startDateTime.getTime() / 1000
          ),
          end_time:
            rootGetters.adSet.campaignKeepRunning ||
            rootGetters.adSet.endDateTime == null
              ? 0
              : Math.floor(rootGetters.adSet.endDateTime.getTime() / 1000),
          // campaign_id: this.adCampaign.id,
          // bid_amount: "300",
          billing_event: "IMPRESSIONS",
          daily_budget: rootGetters.adSet.dailyAdBudget * 100,
          targeting: targeting,
          // billing_event: rootGetters.adSet.billing_event,
          optimization_goal: rootGetters.adSet.optimization_goal,

          // targeting: "{\"age_min\":20,\"age_max\":24,\"behaviors\":[{\"id\":6002714895372,\"name\":\"All travelers\"}],\"genders\":[1],\"geo_locations\":{\"countries\":[\"US\"],\"regions\":[{\"key\":\"4081\"}],\"cities\":[{\"key\":\"777934\",\"radius\":10,\"distance_unit\":\"mile\"}]},\"interests\":[{\"id\":\"6003032339492\",\"name\":\"Ariana Grande\"}],\"life_events\":[{\"id\":6002714398172,\"name\":\"Newlywed (1 year)\"}],\"facebook_positions\":[\"feed\"],\"publisher_platforms\":[\"facebook\",\"audience_network\"]}",
          // status: "PAUSED"
          status: "ACTIVE",
          ...getters.apiTokenParam,
        },
        (response) => {
          dispatch("LOAD_AD_SET", response, { root: true });

          if (response.error) {
            state.adSetWarning.show = true;
            state.adSetWarning.message = response.error.error_user_msg;
            commit(
              "ui/SET_NEW_NOTIFICATION",
              {
                title: response.error.error_user_msg,
                type: "warning",
              },
              { root: true }
            );
            reject(response);
          } else {
            state.adSetWarning.show = false;
            if ("targeting_spec" in response) {
              dispatch("remoteGetDeliveryEstimate", response);
            } else {
              dispatch("remoteGetDeliveryEstimate", {});
            }
          }

          resolve(response);
        }
      );
    });
  },

  remoteGetDeliveryEstimate({ state, commit, getters, rootGetters }, data) {
    // this.loading = true
    commit("ui/SET_IS_FETCHING_NEXT", true);

    let params = { ...getters.apiTokenParam };
    if ("targeting_spec" in data) {
      params.targeting_spec = data.targeting_spec;
    }
    state.FB.api(
      `/${rootGetters.adSet.id}/delivery_estimate`,
      "GET",
      params,
      (response) => {
        this.loading = false;
        if (response.error) {
          return false;
        }
        commit("LOAD_DELIVERY_ESTIMATE", response.data, { root: true });
        commit("ui/SET_IS_FETCHING_NEXT", false);
        // this.deliveryEstimate = response.data
        // this.loading = false
        // this.adSet.dailyAdBudget =
      }
    );
  },

  REMOTE_UPDATE_CAMPAIGN({ state, rootGetters, commit }) {
    state.FB.api(
      `/${rootGetters.adCampaign.id}`,
      "POST",
      {
        objective: "LEAD_GENERATION", // hardcoded for testing purpose
      },
      (response) => {
        if (response.error) {
          commit(
            "ui/SET_CRITICAL_MODAL_DATA",
            {
              body: response.error.message,
              title: response.error.error_user_title,
            },
            { root: true }
          );
        }
      }
    );
  },

  REMOTE_CREATE_AD_CREATIVE({ state, commit, getters, rootGetters }) {
    return new Promise((resolve) => {
      let randomNumber = Math.floor(Math.random() * 99) + 1;
      let adCreateiveName = "Ad Creative " + randomNumber;

      let payload = {
        name: adCreateiveName,
        object_story_spec: rootGetters.objectStorySpec,
        ...getters.apiTokenParam,
      };

      state.FB.api(
        `/${rootGetters.adAccount.id}/adcreatives`,
        "POST",
        payload,
        (response) => {
          if (response.error) {
            state.adSetWarning.show = true;
            state.adSetWarning.message = response.error.error_user_msg
              ? response.error.error_user_msg
              : response.error.message;
            commit(
              "ui/SET_NEW_NOTIFICATION",
              {
                title: response.error.error_user_msg
                  ? response.error.error_user_msg
                  : response.error.message,
                type: "warning",
              },
              { root: true }
            );
          } else {
            state.adSetWarning.show = false;
            commit("UPDATE_AD_CREATIVE_ID", response.id);
          }

          resolve(response); // important
        }
      );
    });
  },
  REMOTE_UPDATE_AD({ state, getters, rootGetters, commit }, ad_config) {
    return new Promise((resolve) => {
      let adName = rootGetters.adName;

      // Call `/agencies` API to gain Ad Account access

      let payload = {
        adset_id: ad_config.ad_id,
        name: adName,
        status: ad_config.new_data.status,
        // "fields": "name,adset_id,creative,status"
        ...getters.apiTokenParam,
      };

      if ("creative" in ad_config.new_data) {
        payload.creative = ad_config.new_data.creative;
      }

      state.FB.api(
        `/${ad_config.ad_id}?fields=creative{name, image_url, object_story_spec},effective_status,issues_info,preview_shareable_link,status`,
        "POST",
        payload,
        (response) => {
          // this.ad.id = response.id

          if (response.error) {
            state.adSetWarning.show = true;
            state.adSetWarning.message = response.error.error_user_msg
              ? response.error.error_user_msg
              : response.error.message;

            commit(
              "ui/SET_NEW_NOTIFICATION",
              {
                title: response.error.error_user_msg
                  ? response.error.error_user_msg
                  : response.error.message,
                type: "warning",
              },
              { root: true }
            );

            commit(
              "ui/SET_CRITICAL_MODAL_DATA",
              {
                body: response.error.error_user_msg
                  ? response.error.error_user_msg
                  : response.error.message,
              },
              { root: true }
            );
          }

          resolve(response);
        }
      );
    });
  },

  GET_AD_GEOLOCATION_METADATA({ state, getters }, data) {
    return new Promise((resolve) => {
      let countries = [];
      let regions = [];
      let cities = [];

      if ("countries" in data.geo_locations) {
        data.geo_locations.countries.forEach((c) => {
          countries.push(c);
        });
      }

      if ("cities" in data.geo_locations) {
        data.geo_locations.cities.forEach((c) => {
          cities.push(c.key);
        });
      }

      if ("regions" in data.geo_locations) {
        data.geo_locations.regions.forEach((c) => {
          regions.push(c.key);
        });
      }

      state.FB.api(
        `/search?type=adgeolocationmeta`,
        {
          countries: countries,
          regions: regions,
          cities: cities,
          ...getters.apiTokenParam,
        },
        (response) => {
          resolve(response);
        }
      );
    });
  },

  LOAD_AD_AND_AUDIENCE_SETTINGS(
    { state, dispatch, getters, rootGetters },
    data
  ) {
    return new Promise((resolve) => {
      state.FB.api(
        `/${data.ad_id}?fields=id,name,adset{id,name,start_time,end_time,billing_event,daily_budget,targeting,optimization_goal,status},campaign{id,name}`,
        getters.apiTokenParam,
        (response) => {
          dispatch("LOAD_AD_SET", { id: response.adset.id });
          dispatch("UPDATE_DAILY_BUDGET", response.adset.daily_budget / 100);

          let adsetInfo = response;

          dispatch("GET_AD_GEOLOCATION_METADATA", {
            geo_locations: adsetInfo.adset.targeting.geo_locations,
          }).then((response) => {
            let demographics_selected_items =
              Object.prototype.hasOwnProperty.call(
                adsetInfo.adset.targeting,
                "flexible_spec"
              )
                ? adsetInfo.adset.targeting.flexible_spec[0]
                : [];
            dispatch("LOAD_AUDIENCE_INFO", {
              selectedAgesFrom: adsetInfo.adset.targeting.age_min,
              selectedAgesTo: adsetInfo.adset.targeting.age_max,
              genders: adsetInfo.adset.targeting.genders,
              geo_locations: adsetInfo.adset.targeting.geo_locations,
              geo_locations_meta: response.data,
              demographics_selected_items: demographics_selected_items,
              start_time: adsetInfo.adset.start_time,
              end_time:
                "end_time" in adsetInfo.adset ? adsetInfo.adset.end_time : null,
            });

            for (const [type, items] of Object.entries(
              demographics_selected_items
            )) {
              // find the detail targeting item in browse data

              items.forEach((item) => {
                let targetingItem = null;

                if (type == "education_statuses") {
                  targetingItem = rootGetters.targetingBrowseData.find(
                    (ele) => {
                      return (
                        Number.parseInt(ele.id) == item && ele.type == type
                      );
                    }
                  );
                } else {
                  targetingItem = rootGetters.targetingBrowseData.find(
                    (ele) => {
                      return ele.id == item.id && ele.type == type;
                    }
                  );
                }

                dispatch("ADD_DETAILED_TARGETING_ITEM", {
                  targetingItem: {
                    id: targetingItem.id,
                    type: targetingItem.type,
                    label: targetingItem.name,
                    path: targetingItem.path.join(" > "),
                  },
                });
              });
            }

            resolve(adsetInfo);
          });
        }
      );
    });
  },

  REMOTE_CREATE_AD({ state, getters, rootGetters, dispatch, commit }) {
    return new Promise((resolve) => {
      let adName = rootGetters.adName;

      // Call `/agencies` API to gain Ad Account access

      state.FB.api(
        `/${rootGetters.adAccount.id}/ads`,
        "POST",
        {
          adset_id: rootGetters.adSet.id,
          name: adName,
          creative: { creative_id: rootGetters.adCreative.id },
          // "status": "PAUSED", // ACTIVE
          status: process.env.NODE_ENV == "production" ? "ACTIVE" : "PAUSED",
          fields:
            "name,adset_id,creative{name, image_url, object_story_spec},status,effective_status,adset{id,targeting,start_time,end_time}",
          ...getters.apiTokenParam,
        },
        (response) => {
          // this.ad.id = response.id

          if (response.error) {
            state.adSetWarning.show = true;
            state.adSetWarning.message = response.error.error_user_msg
              ? response.error.error_user_msg
              : response.error.message;

            commit(
              "ui/SET_NEW_NOTIFICATION",
              {
                title: response.error.error_user_msg
                  ? response.error.error_user_msg
                  : response.error.message,
                type: "warning",
              },
              { root: true }
            );

            commit(
              "ui/SET_CRITICAL_MODAL_DATA",
              {
                body: response.error.error_user_msg
                  ? response.error.error_user_msg
                  : response.error.message,
              },
              { root: true }
            );
          } else {
            state.adSetWarning.show = false;
            dispatch("UPDATE_AD", response);
          }
          resolve(response);
        }
      );
    });
  },
  uploadAdVideo({ state, rootGetters, commit }) {
    return new Promise((resolve) => {
      state.FB.api(
        `/${rootGetters.adAccount.id}/advideos`,
        "POST",
        {
          file_url: rootGetters.mockup.image_url,
        },
        (response) => {
          commit("UPDATE_AD_VIDEO_ID", response.id);
          resolve(response);
        }
      );
    });
  },

  getAdVideo({ state, commit, rootState }) {
    return new Promise((resolve) => {
      state.FB.api(
        `/${rootState.adBuilder.adCopy.videoId}/?fields=permalink_url,embed_html,embeddable,source`,
        "GET",
        (response) => {
          commit("UPDATE_AD_VIDEO_DATA", response);
          resolve(response);
        }
      );
    });
  },

  adVideoThumbnail({ state }, videoId) {
    return new Promise((resolve) => {
      state.FB.api(`/${videoId}/thumbnails`, "GET", (response) => {
        if (response.data.length == 0) {
          resolve(null);
        }

        let preferredThumbnail = response.data.find((ele) => {
          return ele.is_preferred;
        });

        if (preferredThumbnail && preferredThumbnail.length > 0) {
          preferredThumbnail = response.data[0];
        }

        resolve(preferredThumbnail);
      });
    });
  },
  getDetailedTargetingSuggestions({ state, getters, rootGetters }) {
    return new Promise((resolve) => {
      state.FB.api(
        `/${rootGetters.adAccount.id}/targetingsuggestions`,
        "GET",
        {
          // to construct `targeting_list`, use `id` and `type` found in the getter `adSet.detailedTargetingItems`. Below is a hardcoded example:
          targeting_list: getters.detailedTargetingItems,
          ...getters.apiTokenParam,
        },
        (response) => {
          resolve(response);
        }
      );
    });
  },
};

const getters = {
  apiTokenParam: (state) => ({ access_token: state.user.access_token }),
  isFbConnected: (state) => {
    return !!state.user.access_token;
  },
  fb: (state) => {
    return state.FB;
  },
  user_jwt: (state) => {
    return state.user_jwt;
  },
  user: (state) => {
    return state.user;
  },
  user_profile_picture: (state) => {
    return state.user_profile_picture;
  },
  user_full_name: (state) => {
    return state.user_full_name;
  },
  trial_has_ended: (state) => {
    return state.user.trial_end_time < Date.now() / 1000;
  },
  show_trial_end_warning: (state, getters) => {
    return (
      state.user &&
      state.user.email &&
      !(state.user.plan == 1 || state.user.plan == 2) &&
      getters.trial_has_ended
    );
  },
  user_is_premium: (state) => {
    return state.user.plan == 1 || state.user.plan == 2;
  },
  trial_free_limit_reached: (state) => {
    return state.user.ad_created >= 1;
  },
  current_ad_preview_mode: (state) => {
    return state.current_ad_preview_mode;
  },
  available_ctas: (state) => {
    return state.available_ctas;
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
