<template>
  <v-facebook-login
    class="v-facebook-login"
    style="display: none"
    app-id="324415935208431"
    @login="handleLogin"
    @logout="handleLogout"
    v-model="model"
    @sdk-init="handleSdkInit"
    :login-options="loginOptions"
    version="v14.0"
    v-on="$listeners"
  >
    <template v-slot:login>Log in With Facebook</template>
  </v-facebook-login>
</template>

<script>
import VFacebookLogin from "vue-facebook-login-component";
import { mapMutations } from "vuex";
export default {
  components: {
    VFacebookLogin,
  },

  data: () => ({
    model: {},
    loginOptions: {
      scope:
        "email, business_management, ads_management, public_profile, pages_show_list, pages_read_engagement, pages_manage_ads, read_insights, ads_read,  leads_retrieval,  pages_manage_metadata",
      return_scopes: true,
      // auth_type: 'rerequest',
      auth_type: "reauthorize",
      // auth_type: 'reauthenticate',
      enable_profile_selector: true,
    },
  }),
  methods: {
    ...mapMutations(["SET_SCOPE", "SET_FB", "SET_FB_STATUS"]),
    async handleSdkInit({ FB, scope }) {
      this.SET_SCOPE(scope);
      this.SET_FB(FB);
      this.$nextTick(() => {
        this.$store.dispatch("fetchFbUser").then(() => {
          this.$emit("fb-logged-in");
        });
      });
    },
    async handleLogin() {
      if (!this.$store.getters.isFbConnected) {
        await this.$store.dispatch("connectToFacebook");
        this.$emit("fb-logged-in");
      }
    },

    handleLogout() {
      this.$store.dispatch("disconnectFromFacebook");
    },
  },
  watch: {
    model(object) {
      this.SET_FB_STATUS(object);
    },
  },
};
</script>
